import { VueWizard } from '@/vue-wizard';
import { Component, Prop } from 'vue-property-decorator';

@Component({
  name: 'check-box',
})
export default class CheckBox extends VueWizard {
  @Prop() readonly label!: string;
  @Prop() readonly value!: string;
  @Prop({ default: false }) readonly isChecked!: boolean;
  @Prop({ default: false }) readonly disabled!: boolean;

  public checked = false;

  mounted() {
    this.checked = this.isChecked;
  }

  toggleChecked() {
    if (this.checked) {
      this.checked = false;
      this.$emit('un-checked');
    } else {
      this.checked = true;
      this.$emit('checked');
    }
  }

  unCheck() {
    this.checked = false;
  }

  check() {
    this.checked = true;
  }
}
