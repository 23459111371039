import { render, staticRenderFns } from "./ImageHolder.vue?vue&type=template&id=4d684b8c&scoped=true"
import script from "./image-holder.ts?vue&type=script&lang=ts&external"
export * from "./image-holder.ts?vue&type=script&lang=ts&external"
import style0 from "./image-holder.scss?vue&type=style&index=0&id=4d684b8c&prod&lang=scss&scoped=true&external"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4d684b8c",
  null
  
)

export default component.exports