import { VueWizard } from '@/vue-wizard';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import { BASE_URL_MANAGER } from '@/config';
import axios from 'axios';
import $ from 'jquery';

@Component({
  name: 'image-holder',
})
export default class ImageHolder extends VueWizard {
  @Prop() readonly fileUrl!: any;
  @Prop() readonly fileName!: any;
  @Prop({ default: true }) readonly showNativeModal!: boolean;
  @Prop() readonly EventBus!: Vue;

  public loading = false;
  public dataFileUrl: any = '';
  public rotate = 0;

  mounted(): void {
    this.dataUrlToFile();
  }

  changeRotate() {
    if (this.rotate < 3) {
      this.rotate++;
    } else {
      this.rotate = 0;
    }
  }

  changeRotateMin() {
    if (this.rotate === 0) {
      this.rotate = 3;
    } else {
      this.rotate--;
    }
  }

  get formatedFileName() {
    let fileName = Math.round(Math.random() * 100000).toString();
    fileName = `modal-${fileName}`;
    return fileName;
  }

  get currentConversation() {
    return this.$store.state.conversation.currentConversation;
  }
  dataUrlToFile(): void {
    this.loading = true;
    axios
      .post(`${BASE_URL_MANAGER}/company/getfile`, {
        fileName: this.fileName,
        folderName: this.currentConversation.project,
        urlfile: this.fileUrl,
      })
      .then(response => {
        this.dataFileUrl = response.data.file;
        this.loading = false;
      });
  }

  enableModal() {
    if (this.showNativeModal) {
      $(`#${this.formatedFileName}`).modal('show');
    } else {
      this.emitModalEvent();
    }
  }

  disableModal() {
    $(`#${this.formatedFileName}`).modal('hide');
  }

  emitModalEvent() {
    this.EventBus.$emit('image-modal', {
      formatedFileName: this.formatedFileName,
      dataFileUrl: this.fileUrl,
    });
  }
}
